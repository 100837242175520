import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';
import Campus from '../components/campus.js'
import BackgroundBlack from '../assets/desktop_background/barcelona_code_school_background_black.png'
import BackgroundWhite from '../assets/desktop_background/barcelona_code_school_background.png'
import gk3000_oh_my_zsh from '../assets/gk3000_oh_my_zsh_theme.png'

import Logo from '../assets/logos/BCS-LOGO-1000px.jpg'
import LogoNoText from '../assets/logos/BCS-LOGO-1000px-No-Text.jpg'

// import budgetizer01 from '../assets/budgetizer/IMG_2601.jpg'
// import budgetizer02 from '../assets/budgetizer/IMG_2594.jpg'
// import budgetizer03 from '../assets/budgetizer/IMG_2596.jpg'

// import turtleweather01 from '../assets/turtleweather/turtleweather01.jpg'
// import turtleweather02 from '../assets/turtleweather/turtleweather02.jpg'
// import turtleweather03 from '../assets/turtleweather/turtleweather03.jpg'

// import jokesweather01 from '../assets/jokesweather/jokesweather01.jpg'
// import jokesweather02 from '../assets/jokesweather/jokesweather02.jpg'
// import jokesweather03 from '../assets/jokesweather/jokesweather03.jpg'

// import Reachargenow from '../assets/rechargenow/rechargenow.jpg'

import ReactNavbar from '../pics/reactjs-navigation.png'
import showCase from '../pics/alumni_projects_showcase.png'
import ihaveineed from '../pics/lab/ihaveineed_banner.jpg'
import ihaveineedscreens from '../pics/lab/ihaveineed_screens.jpg'
import animecalc from '../pics/lab/anime_calculator.jpg'
import guessaword from '../pics/lab/guessawordordie.jpg'
import weatherOS from '../pics/lab/weather_os.gif'

const Assets = () => (
	<>
	<Helmet

	title="Barcelona Code School – BCS Lab"
	meta={[
		{ name: 'description', content: 'Digital assets, websites and mobile apps from Barcelona Code School' },
		{ name: 'keywords', content: 'barcelona code school, barcelona coding school, bcncoding' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}
	/>

{/*text-type-animation-effect-react
A layout component to create a typewriter typing animation effect for text.

blinking-prompt-react
A blinking prompt animation React component

react-hook-match-media
React hook to use Window.matchMedia() as media query in JavaScript
*/}


	<div className="denseGrid">
		<div className="transparentContainer" style={{"grid-column": "1/-1"}}>
	<h1 className='assetsHeading'>Undefeated Software</h1>
	<article className='whiteCard'>
	<p>Looking for a bespoke website, web application or mobile app for iOS and Android?</p>
	<p><a className='highlighter' href='https://undefeatedsoftware.dev/' target='_blank'>Check out our Undefeated Software agency</a></p>
	</article>
	</div>
	<div className="transparentContainer">
	<h1 className='assetsHeading'>react-hook-match-media</h1>
	<article className='whiteCard'>
	<div>
	<h2>React hook to use Window.matchMedia() as media query in JavaScript</h2>
	<p>With this hook you can determine the screen size and run any logic or apply styles based on that. Essentially it's a media queries inside JavaScript.</p>
	<p><a href="https://www.npmjs.com/package/react-hook-match-media">Get it directly from npmjs with this link.</a></p>
	</div>
	</article>
	</div>

	<div className="transparentContainer">
	<h1 className='assetsHeading'>text-type-animation-effect-react</h1>
	<article className='whiteCard'>
	<div>
	<h2>React blinking prompt component component</h2>
	<p>npm package for a blinking prompt animation React component.</p>
	<p><a href="https://www.npmjs.com/package/blinking-prompt-react">Get it directly from npmjs with this link.</a></p>
	</div>
	</article>
	</div>

	<div className="transparentContainer">
	<h1 className='assetsHeading'>text-type-animation-effect-react</h1>
	<article className='whiteCard'>
	<div>
	<h2>React component for animated typing effect</h2>
	<p>npm package for a React.js component to create animated typing effect</p>
	<img src="https://barcelonacodeschool.com/files/pics/text-type-animation-effect-react.gif" className='banners' style={{width:'100%', marginRight:'3%'}}/>

	<p><a href="https://www.npmjs.com/package/text-type-animation-effect-react">Get it directly from npmjs with this link.</a></p>
	</div>
	</article>
	</div>


	<div className="transparentContainer">
	<h1 className='assetsHeading'>Weather OS</h1>
	<article className='whiteCard'>
	<div>
	<p>A weather app built for all the CLI / terminal aficionados.</p>
	<img className='banners' style={{width:'100%', marginRight:'3%'}} src={weatherOS}/>
	<p>Get it for <a href='https://apps.apple.com/us/app/weather-os/id6443399556' target='blank'>iOS</a> from the App Store</p>
	</div>
	</article>
	</div>

	<div className="transparentContainer">
	<h1 className='assetsHeading'>I have, I need</h1>
	<article className='whiteCard'>
	<div>
	<p>A shopping list for repeating purchases. If you buy the same groceries on a regular basis with this app you can add them to pantry and enable them in the shopping list!</p>
	<img className='banners' style={{width:'100%', marginRight:'3%'}} src={ihaveineed}/>
	<img className='banners' style={{width:'100%', marginRight:'3%'}} src={ihaveineedscreens}/>
	<p>Get it for <a href='https://apps.apple.com/us/app/i-have-i-need/id1621110781' target='blank'>iOS</a> or <a href='https://play.google.com/store/apps/details?id=com.barcelonacodeschool.ihaveineed' target='blank'>Android</a></p>
	</div>
	</article>
	</div>

	<div className="transparentContainer">
	<h1 className='assetsHeading'>Anime Calculator</h1>
	<article className='whiteCard'>
	<div>
	<p>A calculator for all the anime fans which can tell all the numbers in Japanese 😺</p>
	<img className='banners' style={{width:'100%', marginRight:'3%'}} src={animecalc}/>
	<p>Get it for <a href='https://apps.apple.com/us/app/anime-calculator/id1567378874' target='blank'>iOS</a> or <a href='https://play.google.com/store/apps/details?id=com.gk3000.animecalc' target='blank'>Android</a></p>
	</div>
	</article>
	</div>

	<div className="transparentContainer">
	<h1 className='assetsHeading'>Guess a word or die</h1>
	<article className='whiteCard'>
	<div>
	<p>Guess a word or die is a classical guessing game but with a new look – every time we guess correctly, we save a creature from dying!</p>
	<img className='banners' style={{width:'100%', marginRight:'3%'}} src={guessaword}/>
	<p>Get it for <a href='https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298' target='blank'>iOS</a> or <a href='https://play.google.com/store/apps/details?id=com.barcelonacodeschool.guessawordordie' target='blank'>Android</a></p>
	</div>
	</article>
	</div>

	<div className="transparentContainer">
	<h1 className='assetsHeading'>Students projects</h1>
	<article className='whiteCard'>
	<div>
	<p>Take a look at some of the <span className='highlighter'><a href='https://projects.barcelonacodeschool.com/' target='_blank'>students projects</a></span> built during the bootcamp.</p>
	
	<a href='https://projects.barcelonacodeschool.com/'><img className='banners' style={{width:'100%', marginRight:'3%'}} src={showCase}/></a>
	</div>
	</article>
	</div>


	<div className="transparentContainer">
	<h1 className='assetsHeading'>React CSS Grid layout component</h1>
	<article className='whiteCard'>
	<div>
	<h2>npm package for a layout component rendering a Grid container with customizable number of columns, their width and gap between columns/rows.</h2>

	<p><code>react-easy-css-grid-layout</code> is npm package which once installed for your project allows to import and use a Grid component with customizable number/width of columns.</p>

	<p><a href="https://www.npmjs.com/package/react-easy-css-grid-layout">Get it directly from npmjs with this link.</a></p>
	</div>
	</article>
	</div>

	<div className="transparentContainer">
	<article className='whiteCard'>
	<h1 className='assetsHeading'>Oh My Zsh custom theme</h1>
	<div>
	<img
	className='banners' src={gk3000_oh_my_zsh} alt='Oh My Zsh custom theme' style={{width:'100%', marginRight:'3%'}}/>
	<ul>
	<li>Displays full path to current location (without hostname) and a prompt on next line</li>
	<li>Displays current git branch if it exists</li>
	<li>Displays x for uncommitted git history and v for clean</li>
	</ul>
	<p><a href="https://github.com/gk3000/gk3000-oh-my-zsh-theme">Get it from GitHub.</a></p>
	</div>
	</article>
	</div>


	<div className="transparentContainer">
	<h1 className='assetsHeading'>Express Server Generator</h1>
	<article className='whiteCard'>
	<div>
	<h2>npm package for quickly generating the express server files/folders for models, controllers and routes</h2>

	<p><code>express-server-generator</code> is an executable npm package which once installed globally in your system allows you to generate a whole file structure for the server by running <code>esg</code> command in the terminal.</p>

	<p>Check it out for your next project, it will save a lot of time.</p>

	<p><a href="https://www.npmjs.com/package/express-server-generator">Get it directly from npmjs with this link.</a></p>
	</div>
	</article>
	</div>


	<div className="transparentContainer">
	<h1 className='assetsHeading'>Responsive React NavBar</h1>
	<article className='whiteCard'>
	<div>
	<h2>npm package for fully customisable navbar built by our instructor Antonello Sanna</h2>

	<p>Built in a truly React.js way this navbar is totally customisable via props, all the links, items, colors, sizes, logo, etc are passed through props. And it has dropdowns as well!</p>

	<p><a href="https://www.npmjs.com/package/reactjs-navigation">Get it directly from npmjs with this link.</a></p>
	</div>
	</article>
	</div>



	<div className="transparentContainer">
	<h1 className='assetsHeading'>CSS Grid</h1>
	<article className='whiteCard'>
	<div>
	<h2>A simple introduction to the CSS Grid</h2>

	<p>This is a super beginner-friendly introduction made by BCS for the Google I/O 2018 extended talks. An 8-step process, in every step new stuff being added and marked with comments in the html or css file.</p>

	<p><a href="https://github.com/gk3000/Grid-Simple-Workshop">Get it directly from GitHub with this link.</a></p>
	</div>
	</article>
	</div>



	<div className="transparentContainer">
	<h1 className='assetsHeading'>Desktop backgrounds</h1>
	<article className='whiteCard'>
	<div>
	<h2>Black</h2>
	<div>
	<img
	className='banners' 
	src={BackgroundBlack}/>
	</div>
	<h2>White</h2>
	<div>
	<img
	className='banners' 
	src={BackgroundWhite}/>
	</div>
	</div>
	</article>
	</div>


	<div className="transparentContainer">
	<h1 className='assetsHeading'>Logos</h1>
	<article className='whiteCard'>
	<div>
	<h2>With text</h2>
	<div>
	<img
	className='banners' 
	src={Logo}
	alt='Barcelona Code School logo'/>
	</div>
	<h2>No text</h2>
	<div>
	<img
	className='banners' 
	src={LogoNoText}
	alt='Barcelona Code School logo'/>
	</div>
	</div>
	</article>
	</div>


	</div>
	</>
	)

export default Assets
